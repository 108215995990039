import React, { useRef, useState } from 'react';
import useRequestAccess from '../../hooks/useRequestAccess';
import { SEGMENT_FROM } from '../../utils/SegmentConstants';
import InviteCodeModal from '../InviteCodeModal/InviteCodeModal';
import './GetEarlyAccess.scss';

const GetEarlyAccess = () => {
  const [isInviteCodeModalOpen, seIsInviteCodeModalOpen] = useState(false);
  const inputRef = useRef(null);

  const { open } = useRequestAccess(SEGMENT_FROM.EARLY_ACCESS_BANNER, inputRef);

  return (
    <>
      <div className="GetEarlyAccess">
        <img className="img-bg" src="./img/early-access-bg-1.svg" alt="" />
        <div className="early-access">
          <div className="early-access-title">Get early access to Aerotime</div>
          <div className="early-access-description">
            We're gradually opening up early access to companies and teams.
          </div>
          <div className="input-container">
            <input placeholder="Enter your work email" ref={inputRef} />
            <button type="button" className="primary-btn" onClick={open}>
              Get access
            </button>
            <img src="./img/early-access-bg-2.svg" alt="" />
          </div>
          <button
            className="get-code-btn"
            type="button"
            onClick={() => seIsInviteCodeModalOpen(true)}>
            Got an invite code?
          </button>
        </div>
        <div className="divider"></div>
        <div className="img-container">
          <img src="./img/app.png" alt="" />
        </div>
      </div>
      {isInviteCodeModalOpen && (
        <InviteCodeModal onClose={() => seIsInviteCodeModalOpen(false)} />
      )}
    </>
  );
};

export default GetEarlyAccess;
