import React from 'react';
import './Brands.scss';

const brands = [
  'razorpay',
  'spotify',
  'mercury',
  'jupiter',
  'disnep-hotstar',
  'grammarly',
  'gokwik',
  'strava',
  'hubspot',
  'toplyne',
  'meru-health',
  'spotdarft',
  'observe-ai',
];

const Brands = () => (
  <section className="brands-container">
    <div className="brands container">
      <h2>Powering next-gen companies</h2>
      <div className="brand-images">
        {brands.map((brand, index) => (
          <img
            key={brand}
            loading="lazy"
            className={`brand-image ${index > 7 ? 'hidden-mobile' : ''}`}
            src={`/img/brands/${brand}.svg`}
            alt="nextdoor"
          />
        ))}
      </div>
    </div>
  </section>
);

export default Brands;
