import * as React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero/Hero';
import Teams from '../components/Teams/Teams';
// import Customers from '../components/Customers/Customers';
import GetEarlyAccess from '../components/GetEarlyAccess/GetEarlyAccess';
import Brands from '../components/Brands/Brands';

const IndexPage = () => (
  <Layout>
    <SEO title="Aerotime - Be on top of your work" />
    <Hero />
    <Brands />
    <Teams />
    {/* <Customers /> */}
    {/* <FullpageScroll content={followUpsContent} /> */}
    {/* <Metrics /> */}
    {/* <Tasks /> */}
    {/* <Focus /> */}
    {/* <Info /> */}
    {/* <Testimonials /> */}
    {/* <Tools /> */}
    {/* <Articles posts={edges} /> */}
    {/* <CTA /> */}
    <GetEarlyAccess />
  </Layout>
);

/*export const query = graphql`
  {
    allGhostPost {
      edges {
        node {
          title
          url
          id
          og_image
          feature_image
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};*/

export default IndexPage;
