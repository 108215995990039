import React from 'react';
import './YTVideoModal.scss';

const YTVideoModal = (props) => (
  <div className="YTVideoModal">
    <div className="ae-yt__backdrop" onClick={props.onClose}></div>
    <div className="ae-yt__content">
      <div className="ae-yt__video">
        <div
          size="40"
          color="white"
          speed="0.8"
          className="ae-yt__spinner"></div>
        <iframe
          title="youtube video"
          data-src="https://www.youtube.com/embed/YX9pgiaIiSI?showinfo=0"
          frameBorder="0"
          allowFullScreen
          src="https://www.youtube.com/embed/YX9pgiaIiSI?showinfo=0&amp;autoplay=1"></iframe>
      </div>
      <div className="ae-yt__close" onClick={props.onClose}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 5L15 15M5 15L15 5"
            stroke="rgba(0, 0, 0, 0.9)"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
);

export default YTVideoModal;
