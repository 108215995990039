/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import makeAsyncScriptLoader from 'react-async-script';
import GridBackground from '../GridBackground/GridBackground';

import './Hero.scss';
import YTVideoModal from '../YTVideoModal/YTVideoModal';
import InviteCodeModal from '../InviteCodeModal/InviteCodeModal';
import useRequestAccess from '../../hooks/useRequestAccess';
import { SEGMENT_FROM } from '../../utils/SegmentConstants';

const Hero = () => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isInviteCodeModalOpen, seIsInviteCodeModalOpen] = useState(false);
  const inputRef = useRef(null);

  const { open, cta } = useRequestAccess(SEGMENT_FROM.HERO, inputRef);

  // useEffect(() => {
  //   setVideoHeight(`${appVideo.current.offsetWidth / 28.45033}rem`);
  // }, [size, appVideo]);

  return (
    <>
      <div className="hero container">
        <GridBackground />

        <h1 className="heading">
          Reimagine how <br />
          you
          <span className="text-gradient">teamwork.</span>
        </h1>
        <p className="sub-heading">
          Aerotime gets your team in-sync with a <br /> single feed for
          everyones tasks.
        </p>
        <div className="input-container">
          <input placeholder="Enter your work email" ref={inputRef} />
          <button type="button" onClick={open}>
            <span className="text-gradient">{cta}</span>
          </button>
        </div>
        <button
          type="button"
          className="invite-btn"
          onClick={() => seIsInviteCodeModalOpen(true)}>
          I have an invite code →
        </button>
        <img className="back-yc-img" src="./img/back-by-yc.svg" alt="" />
        {isInviteCodeModalOpen && (
          <InviteCodeModal onClose={() => seIsInviteCodeModalOpen(false)} />
        )}
      </div>
      {/* <div className="hero-section">
      <div
          className="app-mock container"
          onClick={() => setIsVideoModalOpen(true)}
          ref={appVideo}>
          <video
            width="100%"
            height="100%"
            loop
            muted
            autoPlay
            playsInline
            preload="metadata">
            <source src="/videos/preview.mp4" type="video/mp4" />
          </video>
          <div className="app-mock__btn">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6 4.80421V19.1972C6 20.6802 7.668 21.5272 8.838 20.6372L18.291 13.4412C19.237 12.7212 19.237 11.2812 18.291 10.5612L8.838 3.36321C7.668 2.47321 6 3.32021 6 4.80421V4.80421Z"
                fill="#fff"
              />
            </svg>
          </div>
        </div>
      </div> */}
      {isVideoModalOpen && (
        <YTVideoModal onClose={() => setIsVideoModalOpen(false)} />
      )}
    </>
  );
};

Hero.defaultProps = {
  lottie: null,
};

Hero.propTypes = {
  lottie: PropTypes.shape({}),
};
// export default Hero;
export default makeAsyncScriptLoader('/lottie/lottie_light.min.js', {
  globalName: 'lottie',
})(Hero);
