/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { Segment } from '../../utils/segment';
import { SEGMENT_ACTIONS, SEGMENT_FROM } from '../../utils/SegmentConstants';
import './InviteCodeModal.scss';

const validCodes = ['AERO07'];

const InviteCodeModal = (props) => {
  const inputRef = useRef(null);
  const [invalid, setInvalid] = React.useState(false);

  const download = () => {
    if (validCodes.includes(inputRef.current.value)) {
      const isMac = /Mac/i.test(navigator.userAgent);
      if (isMac) {
        window.open('https://aerotime.com/downloads/app', '_blank');
        Segment.track(SEGMENT_ACTIONS.DOWNLOAD, {
          From: SEGMENT_FROM.INVITE_MODAL,
        });
      } else {
        Segment.track(SEGMENT_ACTIONS.WEB_INVITE, {
          From: SEGMENT_FROM.INVITE_MODAL,
        });
        window.open(
          'https://app.aerotime.com?utm_source=website_invite',
          '_blank',
        );
      }
      props.onClose();
    } else {
      setInvalid(true);
    }
  };

  return (
    <div
      className="InviteCodeModal"
      onKeyDown={(e) => e.key === 'Escape' && props.onClose()}>
      <div className="backdrop" onClick={props.onClose}></div>
      <div className="invite-card">
        <div className="close" onClick={props.onClose}>
          ✕
        </div>
        <div className="invite-card-title">Enter your invite code</div>
        <div className="invite-card-description">
          Already have an invite code? Please enter it below to access Aerotime.
        </div>
        <input
          type="text"
          className={`invite-input ${invalid ? 'invalid' : ''}`}
          placeholder="Your invite code"
          onChange={(e) => {
            e.target.value = e.target.value.toUpperCase();
            setInvalid(false);
          }}
          ref={inputRef}
          autoFocus={true}
        />
        <button type="button" className="primary-btn" onClick={download}>
          Get access →
        </button>
      </div>
    </div>
  );
};

export default InviteCodeModal;
