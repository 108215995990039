import React from 'react';
import './GridBackground.scss';

const GridBackground = () => (
  <div className="grid-background-image">
    <img
      loading="lazy"
      src="/img/hero.png"
      alt="grid"
      style={{ width: '100%' }}
    />
  </div>
);

export default GridBackground;
