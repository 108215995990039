import React from 'react';
import './Teams.scss';

const teams = [
  {
    id: 'sales',
    title: 'Sales Teams',
    description: `Solves internal collaboration, move tasks between stakeholders quickly right in Slack.`,
  },
  {
    id: 'customer-success',
    title: 'Customer Success Teams',
    description: `Optimize your customer success flow by capturing tasks efficiently.`,
  },
  {
    id: 'product',
    title: 'Product Teams',
    description: `Designers, writers, and makers rejoice! Aerotime levels-up your existing tools.`,
  },
  {
    id: 'engineering',
    title: 'Engineering Teams',
    description: `Engineers are capturing work across apps with Aerotime. Learn how it works.`,
  },
];

const Teams = () => (
  <section className="Teams">
    <div className="title">Teams on Aerotime run fast.</div>
    <div className="description">
      Whether you’re a Sales MVP or Product Guru, or something in between—
      discover how you can get things done.
    </div>
    <div className="teams">
      {teams.map((team) => (
        <div className="team" key={team.id}>
          <img src={`./img/${team.id}-team.svg`} className="team-img" alt="" />
          <div className="team-info">
            <div className="team-title">
              <img
                src={`./img/${team.id}-icon.svg`}
                className="team-icon"
                alt=""
              />
              {team.title}
            </div>
            <div className="team-description">{team.description}</div>
            {/* <div className="team-read-more">Read more ↗</div> */}
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default Teams;
